import Chip, { type ChipProps } from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from '@mui/icons-material/Info';
import { Direction as DirectionEnum } from "consts/transactions";
import When from "ui/molecules/When";
import withGridCellParams from "./withGridCellParams";

const Direction = withGridCellParams<DirectionEnum>(({ value }) => (
    <When
        if={Boolean(value)}
        then={(
            <Chip
                size='small'
                label={value}
                {...(new Map<DirectionEnum, ChipProps>([
                    [DirectionEnum.C, {
                        color: 'success',
                        icon: (
                            <Tooltip
                                title='Credit (funds received)'
                                arrow
                            >
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        )
                    }],
                    [DirectionEnum.D, {
                        color: 'error',
                        icon: (
                            <Tooltip
                                title='Debit (funds withdrawn)'
                                arrow
                            >
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        )
                    }]
                ]).get(value ?? DirectionEnum.D))}
            />
        )}
        else={'-'}
    />
));

export default Direction;
