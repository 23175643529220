import { Filters, ParentVisibility } from "consts/filters";
import { ApiState } from "infrastructure/api";
import { GeneralState } from "./types";

const state: GeneralState = {
  isSideMenuOpen: true,
  notifications: [],

  constants: {
    CsvSettlementStatus: [],
    TransactionFundStatus: [],
    TransactionPayoutStatus: [],
    TransactionStatus: [],
    Currencies: [],
    TransactionCurrencies: [],
    PSPs: [],
    TransactionSettlementStatus: [],
    Via: [],
    MerchantPayoutCalculationEnums: [],
    MerchantPayoutIncrementEnums: [],
    MerchantPayoutIncrementTaxName: [],
    MerchantPayoutIncrementTaxRefColumnsFrom: [],
    MerchantPayoutFileTaxName: [],
    MerchantPayoutIncrementsVia: {},
    KYCStatusEnums: [],
    KYCStatusesMap: [],
    SamePersonIdentifierSourceTypes: [],
    ObResultStatuses: [],
    CardIssuer: [],
    TransactionDirections: []
  },
  domains: null,
  userPreferences: {
    preferences: {}
  },
  settings: null,
  ledgers: null,
  domainsLoadingState: ApiState.Idle,
  constantsLoadingState: ApiState.Idle,
  userPreferencesLoadingState: ApiState.Idle,
  settingsLoadingState: ApiState.Idle,
  ledgersLoadingState: ApiState.Idle,
  // Additional auxuiliary definitions
  extras: {
    [Filters.parentVisibility]: [
      { id: ParentVisibility.Children, name: 'only children' },
      { id: ParentVisibility.Parents, name: 'only parents' }
    ]
  },

  countries: [],
  countriesLoadingState: ApiState.Idle
};

export const sliceToken = 'general';

export default state;
