import {
    memo,
    type PropsWithChildren,
    type ComponentType
} from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getFormInputProps } from "util/forms";
import { FormField, type RefundAmountForm } from "./types";
import { useForm } from "./useForm";

type Props = RefundAmountForm & {
    readonly SubmitActionComponent: ComponentType<ReturnType<typeof useForm>>;
    readonly sx?: BoxProps['sx'];
};

const RefundAmount = ({
    sx,
    children,
    SubmitActionComponent,
    ...restProps
}: PropsWithChildren<Props>) => {
    const form = useForm(restProps);

    const {
        values,
        handleChange,
        handleBlur
    } = form;

    return (
        <Box
            component='form'
            sx={sx}
        >
            {children}
            <TextField
                {...getFormInputProps(FormField.Amount, form)}
                type='number'
                inputProps={{
                    min: 0,
                    step: 0.01
                }}
                fullWidth
                size='small'
                name={FormField.Amount}
                value={values[FormField.Amount]}
                label='Enter refund amount (Max amount already selected)'
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <SubmitActionComponent
                {...form}
            />
        </Box>
    );
};

export default memo(RefundAmount);
