import type { ID, Option as OptionType, Scalar } from "types";
import { compareIds } from "./support";

export class Option {
    public static make(id: ID, name?: Scalar<string>): OptionType {
        return {
            id,
            name: String(name ?? id)
        };
    }
}

export const getOptionName = (option: OptionType | undefined) =>
    option?.name ?? '';

export const getOptionById = (options: Array<OptionType>, id: OptionType['id'] | undefined | null) =>
    options.find(option => compareIds(option.id, `${id}`));
