import { memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { LoadingButton } from "ui/atoms/Button";
import { useForm, FormField } from "ui/forms/Transaction/RefundAmount";
import { TriggerableDialog } from "ui/molecules/Dialog";
import { CurrencySymbol } from "consts/general";

type Props = ReturnType<typeof useForm> & {
    readonly onCancel: () => void;
};

const SubmitRefundAction = ({
    isSubmitting,
    isValid,
    values,
    submitForm,
    onCancel
}: Props) => (
    <Box
        sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'flex-end',
            mt: 2
        }}
    >
        <Button
            variant='outlined'
            onClick={onCancel}
        >
            Cancel
        </Button>
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <LoadingButton
                    variant='contained'
                    color='secondary'
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={onClick}
                >
                    Proceed
                </LoadingButton>
            )}
            DialogActionsComponent={({ onClose }) => (
                <>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        color='secondary'
                        loading={isSubmitting}
                        onClick={() => {
                            onClose();
                            onCancel();
                            submitForm();
                        }}
                    >
                        Confirm
                    </LoadingButton>
                </>
            )}
            ModalProps={{
                DialogProps: {
                    sx: {
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: '100%',
                            maxWidth: 445
                        }
                    }
                },
                titleSlot: 'Do you want to proceed with refund?'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 1
                }}
            >
                <Typography
                    color='primary'
                    sx={{
                        fontFamily: 'inherit',
                        fontSize: 24,
                        fontWeight: 600
                    }}
                    gutterBottom
                >
                    {values[FormField.Amount]}
                </Typography>
                <Typography
                    color='text.disabled'
                    sx={{
                        fontFamily: 'inherit',
                        fontSize: 24,
                        fontWeight: 600
                    }}
                    gutterBottom
                >
                    {CurrencySymbol.EUR}
                </Typography>
            </Box>
        </TriggerableDialog>
    </Box>
);

export default memo(SubmitRefundAction);
