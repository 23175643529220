import {
    FormField,
    type RefundAmount,
    type RefundAmountForm
} from "./types";
import useValidationSchema from "./useValidationSchema";
import { useForm as useFormHook } from "hooks";

export const getInitialValues = (initialValues?: Partial<RefundAmount>) => ({
    [FormField.Amount]: 0,
    ...initialValues,
});

export const useForm = ({ initialValues, ...restProps }: RefundAmountForm) =>
    useFormHook<RefundAmount>({
        validateOnMount: true,
        enableReinitialize: true,
        ...restProps,
        validationSchema: useValidationSchema(initialValues),
        initialValues: getInitialValues(initialValues)
    });
