import { Event, TRUST_DEVICE_TOKEN_EXPIRES_DAYS } from "consts/auth";
import useCookieState from "hooks/useCookieState";
import { useSimpleRequest, type UseSimpleRequestReturnCallbackArg } from "hooks/useRequest";
import { publish } from "util/custom-event";
import { verify as verify2FA, resend2FACode } from "features/auth/api";
import type { TwoFactorVerifyResponse } from "features/auth/types";
import type { Verify2FA } from "ui/forms/Auth";

export default function useVerify2FA() {
    const [, setTrustToken] = useCookieState({
        cookieKey: 'trustToken'
    });

    const request = useSimpleRequest();

    const resend = ({ onSuccess }: Pick<UseSimpleRequestReturnCallbackArg, 'onSuccess'>) =>
        request(resend2FACode, {
            notifyOnSuccess: true,
            onSuccess
        });

    const verify: Verify2FA.TwoFactorCodeFormProps['onSubmit'] = ({
        twoFactorCode,
        withTrustToken
    }, helpers) => request<TwoFactorVerifyResponse['data']>(() => verify2FA({
        withTrustToken,
        twoFactorCode: twoFactorCode!
    }), {
        notifyOnSuccess: true,
        onSuccess: ({ data }) => {
            helpers!.resetForm();
            setTrustToken(data.trustToken, {
                expires: TRUST_DEVICE_TOKEN_EXPIRES_DAYS
            });
            publish(Event.GetUser, data);
        }
    });

    return {
        verify,
        resend
    };
};
