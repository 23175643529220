import { memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getFormInputProps } from "util/forms";
import { Checkbox } from "ui/forms/formik-elements";
import { FormField, type TwoFactorCodeFormProps } from "./types";
import useForm from "./useForm";
import SubmitButton from "../SubmitButton";

const Verify2FA = ({
    onSubmit,
    resendCodeSlot,
    trustDeviceSlot,
    ...props
}: TwoFactorCodeFormProps) => {
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        values,
        isValid,
        isSubmitting,
        dirty,
        ...formik
    } = useForm({
        onSaveOrCreate: onSubmit
    });

    return (
        <Box
            {...props}
            component='form'
            onSubmit={handleSubmit}
            autoComplete='off'
            noValidate
        >
            <TextField
                required
                fullWidth
                margin='normal'
                label='Enter one time code'
                name={FormField.TwoFactorCode}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[FormField.TwoFactorCode]}
                {...getFormInputProps(FormField.TwoFactorCode, formik)}
            />
            {resendCodeSlot}
            <Checkbox
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                label='Trust this device'
                handleBlur={handleBlur}
                CheckboxProps={{
                    inputProps: {
                        'aria-label': 'Trust this device'
                    },
                    name: FormField.WithTrustToken,
                    checked: Boolean(values[FormField.WithTrustToken])
                }}
            />
            {trustDeviceSlot}
            <SubmitButton
                disabled={!isValid || !dirty}
                loading={isSubmitting}
            >
                Verify 2FA
            </SubmitButton>
        </Box>
    );
};

export default memo(Verify2FA);
