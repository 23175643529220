import { memo } from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import useTransactionActions from "./useTransactionActions";
import { Transaction, TransactionAction } from "features/transactions/types";
import RefundAction from "./RefundAction";
import PartialRefundAction from "./PartialRefundAction";

type Props = Pick<
  Transaction,
  | 'coreId'
  | 'createdAt'
  | 'via'
  | 'status'
  | 'fundStatus'
  | 'paymentMethod'
  | 'pingTransactionCoreId'
  | 'isEcommerce'
  | 'refundableAmount'
  | 'paymentMethodName'
>;

const TransactionActions = (props: Props) => {
    const {
        checkStatus,
        refund,
        actionLoading
    } = useTransactionActions(props);

    const commonActionProps: Partial<LoadingButtonProps> = {
        variant: "outlined",
        size: "small",
        disabled: !props.coreId
    };

    return (
        <Stack px={2} pb={2} direction="row" spacing={1} divider={<Divider />}>
            <LoadingButton
                {...commonActionProps}
                loading={actionLoading[TransactionAction.CheckStatus]}
                onClick={checkStatus}
                startIcon={<ArchitectureIcon />}
            >
                Check status
            </LoadingButton>
            <RefundAction
                {...props}
                ModalProps={{
                    DialogProps: {
                        PaperProps: {
                            sx: {
                                maxWidth: '600px !important'
                            }
                        }
                    }
                }}
                {...commonActionProps}
                onProceed={refund}
                loading={actionLoading[TransactionAction.Refund]}
                startIcon={<AccountBalanceWalletIcon />}
            />
            <PartialRefundAction
               {...props}
            />
        </Stack>
    );
};

export default memo(TransactionActions);
