import { memo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Modal from 'ui/atoms/Modal';
import type { BasicDialogProps } from './Props';

const Basic = ({
    actionsSlot,
    titleSlot,
    onClose,
    isCanClose = true,
    DialogProps = {},
    ...restProps
}: BasicDialogProps) => {
    const handleClose: BasicDialogProps['onClose'] = (...args) => {
        if (isCanClose) {
            onClose(...args);
        }
    };

    const renderTitleSlot = () =>
        titleSlot && (
            <Box
                sx={{
                    px: 2,
                    pr: 4
                }}
            >
                {titleSlot}
            </Box>
        );

    const renderActionsSlot = () =>
        actionsSlot && (
            <Stack
                direction='row'
                spacing={1}
            >
                {actionsSlot}
            </Stack>
        );

    return (
        <Modal
            {...restProps}
            DialogProps={{
                ...DialogProps,
                sx: {
                    '& .MuiDialog-container .MuiPaper-root': {
                        maxWidth: '790px'
                    },
                    ...(DialogProps.sx ?? {})
                }
            }}
            onClose={handleClose}
            titleSlot={renderTitleSlot()}
            actionsSlot={renderActionsSlot()}
        />
    );
};

export default memo(Basic);
