import type { BootstrapableForm } from "types";

export const enum FormField {
    Amount = 'amount'
};

export type RefundAmount = {
    readonly [FormField.Amount]: number;
};

export type RefundAmountForm = Pick<
    BootstrapableForm<RefundAmount, {}>,
    | 'initialValues'
    | 'onSaveOrCreate'
>;
