import { useMemo } from "react";
import { object, string } from "yup";
import { FormField, type RefundAmount } from "./types";

export default function useValidationSchema({ amount = 0 }: Partial<RefundAmount> = {}) {
    return useMemo(() =>
        object().shape({
            [FormField.Amount]: string()
                .matches(/^\d+(\.\d{1,2})?$/, 'Refund amount must be a valid number with up to 2 decimals')
                .test(
                    'is-positive',
                    'Refund amount must be greater than 0',
                    value => Number.parseFloat(String(value)) > 0
                )
                .test(
                    'does-not-exceed',
                    `Refund amount must not exceed ${amount}`,
                    value => Number.parseFloat(String(value)) <= amount
                )
                .required()
                .label('Refund Amount'),
        })
        , [amount]);
};
