import { memo, type ReactNode } from "react";
import { RESEND_OTP_TIMEOUT_SEC } from "consts/auth";
import { TimeoutableAction } from "ui/widgets/Action";
import useVerify2FA from "./useVerify2FA";

type Props = {
    readonly resendCodeTimeout?: number;
};

const ResendCode = ({ resendCodeTimeout = RESEND_OTP_TIMEOUT_SEC }: Props) => {
    const { resend } = useVerify2FA();

    const renderChildren = (currentTimeout?: number): [ReactNode, ReactNode] => [
        'Resend code',
        `Resend code in ${currentTimeout} seconds`
    ];

    return (
        <TimeoutableAction
            cookieKey='resendOTPWithin'
            timeoutSec={resendCodeTimeout}
            request={resend}
            render={renderChildren}
        />
    );
};

export default memo(ResendCode);
