import { OpenBankingResultStatus } from "consts/transactions";
import { ApiState } from "infrastructure/api";
import { RootState } from "infrastructure/store";
import type { Option } from "types";
import { capitalizeFirstLetterFormatter, removeUnderscoreFormatter } from "util/formaters";
import { compareIds } from "util/support";
import { convertToOptions } from "util/transformers";
import type { Country, Ledger } from "./types";

export const selectMenuOpenState = (state: RootState) =>
  state.general.isSideMenuOpen;

export const selectNotification = (state: RootState) =>
  state.general.notifications;

export const selectConstants = (state: RootState) =>
  state.general.constants;

export const selectIsBootstrapDataLoading = (state: RootState) =>
  state.general.constantsLoadingState === ApiState.Pending;

export const selectIsBootstrapDataSucceeded = (state: RootState) =>
  state.general.constantsLoadingState === ApiState.Succeeded;

export const selectCountries = (state: RootState) =>
  state.general.countries;

export const selectSettings = (state: RootState) =>
  state.general.settings;

export const selectPayoutCurrency = (state: RootState) =>
  selectSettings(state)?.payoutCurrency ?? 'EUR';

export const selectDomains = (state: RootState) =>
  state.general.domains;

export const selectIsCountriesLoading = (state: RootState) =>
  state.general.countriesLoadingState === ApiState.Pending;

export const selectDomainsOptions = (state: RootState) =>
  Object.keys(state.general.domains?.setupValue ?? {});

export const selectBootstrapData = (state: RootState) => ({
  ...selectConstants(state),
  Domains: selectDomainsOptions(state)
});

export const selectMerchantPayoutIncrementsVia = (state: RootState) =>
  convertToOptions(
    state.general.constants.MerchantPayoutIncrementsVia,
    capitalizeFirstLetterFormatter
  )

export const selectViaAutoSuggestModel = (state: RootState) =>
  state.general.constants.Via.map(({ name }: Option) => ({
    id: name
      .charAt(0)
      .toLocaleLowerCase(),
    name
  }));

export const selectCalulationStatus = (state: RootState) =>
  state.general.constants.MerchantPayoutCalculationEnums.map((calculationStatus: string) => ({
    id: calculationStatus,
    name: removeUnderscoreFormatter(calculationStatus)
  }));

export const selectFileTaxName = (state: RootState) =>
  state.general.constants.MerchantPayoutFileTaxName;

export const selectParentVisibility = (state: RootState) =>
  state.general.extras.parentVisibility;

export const selectObResultStatusText = (obResultStatus: OpenBankingResultStatus) =>
  (state: RootState) =>
    state.general.constants.ObResultStatuses.find(({ id }: Option) =>
      compareIds(id, obResultStatus))?.name ?? '';

export const selectCountryByProperty = ([propertyName, value]: [keyof Country, string]) =>
  (state: RootState) =>
    state.general.countries.find((country: Country) =>
      compareIds(country[propertyName], value));

export const selectUserPreferences = (state: RootState) =>
  state.general.userPreferences;

export const selectUserPreferencesLoadingState = (state: RootState) =>
  state.general.userPreferencesLoadingState;

export const selectKycTypesMap = (state: RootState) =>
  selectConstants(state).KycTypesMap;

export const selectIsLedgersLoading = (state: RootState) =>
  state.general.ledgersLoadingState === ApiState.Pending;

export const selectLedgerCurrencies = (state: RootState) =>
  state.general.ledgers
    ?.data.map(({ currency }: Ledger) => currency) ?? [];
