import { memo, type FunctionComponent } from "react";
import { useFormik, type FormikConfig } from "formik";
import type { LoadingButtonProps } from "@mui/lab/LoadingButton";
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { getFormInputProps } from "util/forms";
import When from "ui/molecules/When";
import SubmitButton from "../SubmitButton";
import useValidationSchema from "./useValidationSchema";
import {
    FormField,
    type Credentials as CredentialsType,
    type CredentialsProps
} from "./types";

type Props =
    & CredentialsProps
    & Pick<
        FormikConfig<CredentialsType>,
        | 'validationSchema'
    >
    & {
        readonly children?: FunctionComponent<ReturnType<typeof useFormik>>;
        readonly initialValues?: CredentialsType;
        readonly SubmitButton?: FunctionComponent<
            Readonly<{
                form: ReturnType<typeof useFormik>;
                SubmitButtonProps?: LoadingButtonProps;
            }>
        >;
        readonly SubmitButtonProps?: LoadingButtonProps;
        readonly TextFieldProps?: TextFieldProps;
    };

const Credentials = ({
    children: Component,
    initialValues,
    TextFieldProps,
    SubmitButton: SubmitButtonComponent,
    SubmitButtonProps,
    ...restProps
}: Props) => {
    const form = useFormik({
        validateOnMount: true,
        validationSchema: useValidationSchema(),
        ...restProps,
        initialValues: {
            [FormField.Email]: '',
            ...initialValues
        }
    });

    return (
        <Box
            component='form'
            onSubmit={form.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
        >
            <TextField
                margin='normal'
                required
                fullWidth
                id={FormField.Email}
                label='Email Address'
                autoComplete='email'
                type='email'
                autoFocus
                name={FormField.Email}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.email}
                {...TextFieldProps}
                {...getFormInputProps(FormField.Email, form)}
            />
            {Component && (
                <Component {...form} />
            )}
            <When
                if={Boolean(SubmitButtonComponent)}
                then={() => {
                    const Component = SubmitButtonComponent!;

                    return (
                        <Component
                            form={form}
                            SubmitButtonProps={SubmitButtonProps}
                        />
                    );
                }}
            >
                <SubmitButton
                    {...SubmitButtonProps}
                    disabled={SubmitButtonProps?.disabled || !form.isValid || !form.dirty}
                    loading={SubmitButtonProps?.loading || form.isSubmitting}
                />
            </When>
        </Box>
    );
};

export default memo(Credentials);
