import { ApiState } from "infrastructure/api";
import type {
    BooleanSwitch,
    ID,
    Mapped,
    PaginateResourceResponse,
    ResourceResponse,
    Timestamps
} from "types";
import { WebRouteTypes } from "consts/enpoints/web";

export enum TransactionMode {
    Transaction = WebRouteTypes.Transactions
};

type FilterModelEntity = {
    readonly id: string;
    readonly name: string;
};

interface AbstractFiltersModel {
    readonly [filterName: string]: Array<FilterModelEntity>
}

export interface TransactionsFilterModel extends AbstractFiltersModel {
    readonly CsvSettlementStatus: Array<FilterModelEntity>;
    readonly TransactionFundStatus: Array<FilterModelEntity>;
    readonly TransactionPayoutStatus: Array<FilterModelEntity>;
    readonly TransactionStatus: Array<FilterModelEntity>;
    readonly merchants: Array<FilterModelEntity>;
    readonly paymentMethods: Array<FilterModelEntity>;
};

export type TransactionsFilterModelWithCustomFields = TransactionsFilterModel & {
    // readonly dateLength?: Array<FilterModelEntity>;
    readonly transactionId?: Array<FilterModelEntity>;
    readonly Guid?: Array<FilterModelEntity>;
    readonly RefId?: Array<FilterModelEntity>;
    readonly consumerId?: Array<FilterModelEntity>;
    readonly trackingMemberCode?: Array<FilterModelEntity>;
};

export type TransactionsState = {
    transactionsLoadingState: ApiState;
    exportLoadingState: ApiState;
    transactionHistoryLoadingState: ApiState;
    notificationsInLoadingState: ApiState;
    notificationsOutLoadingState: ApiState;
    transactionDetailsLoadingState: ApiState;
    readonly transactionSlice: TransactionsResponse['data'] | null;
};

type JSONCode = string;

export type Transaction = {
    readonly coreId: ID;
    readonly amount: number;
    readonly amountEur: number;
    readonly consumer: string;
    readonly consumerId: number;
    readonly consumerIp: string;
    readonly consumerIban: string;
    readonly countryIso2: string;
    readonly currency: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly fundStatus: string;
    readonly id: number;
    readonly merchant: string;
    readonly sourceUrl: string;
    readonly sourceIp: string;
    readonly paymentMethod: string; /// -> looks like wrong naming
    readonly paymentMethodName: string;
    readonly isPaid: boolean;
    readonly refId: string;
    readonly refererUrl: string;
    readonly status: string;
    readonly trackingMemberCode: string;
    readonly transactionId: string;
    readonly type: string;
    readonly PSP: string;
    readonly isSettled: boolean;
    readonly resultMessage?: string;
    readonly payoutId: number;
    readonly transactionGuid: string;
    readonly descriptor: string;
    readonly direction: string;
    readonly transactionRequest: JSONCode;
    readonly transactionResponse: JSONCode;
    readonly transactionDecision: JSONCode;
    readonly settlementStatus: string;
    readonly settlementStatusDateTime: string;
    readonly via: string;
    readonly pingTransactionCoreId: ID;
    readonly methodTag: string | null;
    readonly obResultStatus: string | null;
    readonly pki: ID;
    readonly isEcommerce: BooleanSwitch;
    readonly refundableAmount: number;
};

export type TransactionsResponse = PaginateResourceResponse<Transaction>;

export type TransactionDetail = Transaction & {
    /**TODO: write proper types */
    readonly statusesHistory: Mapped<string>;
};

export type TransactionDetailResponse = ResourceResponse<TransactionDetail>;

export type TransactionHistory = PaginateResourceResponse<Transaction>;
// {
//     readonly data: Array<Transaction>;
// };

export type TransactionExportProcessResponse = string | {
    readonly current: number;
    readonly memory: string;
    readonly percentage: number;
    readonly timePass: number;
    readonly total: number;
};

export type TransactionNotificationsRequest = {
    readonly id: ID;
};

export type Notification = {
    readonly notificationUrl: string;
    readonly createdAt: string;
    readonly historyCreatedAt: string;
    readonly transactionId: string;
    readonly transactionGuid: string;
};

export type InNotification = Notification & {
    readonly refId: string;
    readonly receivedPayload: string;
    readonly sentResponse: string;
};

export type OutNotification = Notification & {
    readonly payload: string;
    readonly receivedResponse: string;
};

export type Notifications<TNotification> = Array<TNotification>;

export type NotificationsResponse<TNotification> = PaginateResourceResponse<TNotification>;

export type TransactionActionResponse = ResourceResponse<{
    readonly message: string;
}>;

export const enum TransactionAction {
    SendNotification = 'SendNotification',
    CheckStatus = 'CheckStatus',
    FakeRedirect = 'FakeRedirect',
    SettlePingOrder = 'SettlePingOrder',
    ClosePingOrder = 'ClosePingOrder',
    Refund = 'Refund'
};

export type TransactionOrigin = Timestamps & {
    readonly consumerId: ID;
    readonly consumerIp: string;
    readonly coreTransactionId: ID;
    readonly iban: string;
    readonly merchantId: ID;
    readonly merchantName: string;
    readonly userAgent: string;
};
