import { memo } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { FilterRenderTypes, Filters, SelectedFilters, useFiltersEvents } from "ui/widgets/Filters";
import { useStatisticDashboardContext } from "../StatisticDashboardProvider";
import { useTypedSelector } from "hooks";
import { selectIsBootstrapDataLoading } from "features/general/selectors";
import { Card } from "@mui/material";

const StatisticDashboardFilters = () => {
    const {
        filters,
        getFilterOptions,
        onChange,
        onReset,
        onApply,
        isLoading
    } = useStatisticDashboardContext();

    const isBootstrapDataLoading = useTypedSelector(selectIsBootstrapDataLoading);

    useFiltersEvents({
        [FilterRenderTypes.PopoverDropdown]: {
            onApply
        }
    });

    return (
        <Card
        sx={{
            p: 3
        }}
        >
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <Stack
                    m={2}
                    ml={0}
                    mt={0}
                    gap={2}
                    direction='row'
                    flexWrap='wrap'
                >
                    {filters.map(filter => {
                        const filterProps = {
                            renderAs: filter.renderAs,
                            props: {
                                ...getFilterOptions(filter.props.id),
                                ...filter.props
                            }
                        };

                        return (
                            <Filters
                                key={filter.props.id}
                                {...filterProps}
                                onChange={onChange}
                            />
                        );
                    })}
                </Stack>
                <Stack
                    gap={2}
                    direction='row'
                >
                    <Button
                        sx={{
                            '& .MuiSvgIcon-root': {
                                transition: '.9s',
                            },
                            ':hover': {
                                '& .MuiSvgIcon-root': {
                                    transform: 'rotate(-360deg)'
                                }
                            }
                        }}
                        variant='outlined'
                        size='small'
                        startIcon={<RestartAltIcon />}
                        onClick={onReset}
                    >
                        Reset filters
                    </Button>
                    <LoadingButton
                        variant='outlined'
                        size='small'
                        loading={isLoading}
                        onClick={onApply}
                    >
                        Apply filters
                    </LoadingButton>
                </Stack>
            </Box>
            <SelectedFilters
                isOpen={false}
                isBootstrapDataLoading={isBootstrapDataLoading}
                filters={filters}
                getFilterOptions={getFilterOptions}
                onChange={onChange}
            />
        </Card>
    );
};

export default memo(StatisticDashboardFilters);
