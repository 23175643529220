import { memo } from 'react';
import clsx from 'clsx';
import Typography from "@mui/material/Typography";
import { SignIn } from 'ui/layouts/SignIn';
import { Credentials } from "ui/forms/Auth";
import useSendResetLink from './useSendResetLink';
import { BackToLogin } from '../support';

const SendResetLink = () => {
    const {
        count,
        disabled,
        sendResetLink
    } = useSendResetLink();

    return (
        <SignIn
            title='Forgot Password'
        >
            <Typography
                sx={{
                    color: 'text.secondary'
                }}
            >
                Please enter your email to reset password
            </Typography>
            <Credentials.Form
                onSubmit={sendResetLink}
                SubmitButtonProps={{
                    disabled,
                    children: clsx(
                        'Send Reset Link',
                        disabled && ` (${count}) sec`
                    )
                }}
            >
                {BackToLogin}
            </Credentials.Form>
        </SignIn>
    );
};

export default memo(SendResetLink);
