import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import Box from "@mui/material/Box";
import { Filters as TransactionFilters } from "consts/transactions";
import { compareIds } from "util/support";
import type { AbstractFilterProps, AutocompleteProps, Filter } from "ui/widgets/Filters";

export default function useFilterPropsOverride(_: Filter<AbstractFilterProps>[]) {
    const isDirectionFilter = (filter: Filter<AbstractFilterProps>) =>
        compareIds(filter.props.id, TransactionFilters.direction);

    return (filter: Filter<AbstractFilterProps>) => {
        let propsOverrides = {};

        if (isDirectionFilter(filter)) {
            const props: AbstractFilterProps & Pick<AutocompleteProps, 'label'> = filter.props;

            propsOverrides = {
                label: (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                        }}
                    >
                        <Tooltip
                            title='Direction indicates the transaction type: ‘C’ stands for Credit (funds received) and ‘D’ stands for Debit (funds withdrawn).'
                            arrow
                        >
                            <InfoIcon
                                color='info'
                            />
                        </Tooltip>
                        {props.label}
                    </Box>
                )
            };
        }

        return propsOverrides as Filter<AbstractFilterProps>;
    };
};
